import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './message_detail.css';
import { useSelector } from 'react-redux';
import LoadingAnimate from '../../UI-kit/loading';
import { IoCheckmarkOutline, IoCheckmarkDoneSharp } from 'react-icons/io5';
import { FiChevronLeft, FiSend } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { api } from '../../Api';

export default function MessageDetails() {
    const { sender, receiver } = useParams();
    const [newMessage, setNewMessage] = useState('');
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [messagebox, setMessagebox] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const messageContainerRef = useRef(null);
    const user_info = localStorage.getItem('userID');

    useEffect(() => {
        const fetchMessages = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/chat/get-messages/${sender}/${receiver}`);
                setMessagebox(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessages();
    }, [sender, receiver]);

    useEffect(() => {
        scrollToBottom();
    }, [messagebox]);

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        setIsMessageSent(true);
        try {
            await api.post('/chat/send-messages/', {
                user: user_info,
                sender: user_info,
                receiver: user_info === sender ? receiver : sender,
                message: newMessage,
            });
            setNewMessage('');

            setIsLoading(true);
            const response = await api.get(`/chat/get-messages/${sender}/${receiver}`);
            setMessagebox(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsMessageSent(false);
            setIsLoading(false);
        }
    };

    return (
        <div className="message-details-page">
            <div className="message-details-header">
                <FiChevronLeft color="#fdb602" onClick={() => navigate('/messages')} size={30} />
                <MdDelete color="red" size={30} />
            </div>
            <div ref={messageContainerRef} className="message-container">
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingAnimate />
                    </div>
                ) : messagebox.length === 0 ? (
                    <p className="empty-chat">Чат пуст</p>
                ) : (
                    messagebox.map((message, index) => (
                        <div key={index} className={`message-card ${user_info === message.sender ? 'sent' : 'received'}`}>
                            <div className="is-read-message-details">
                                {user_info === message.sender && (
                                    message.is_read ? <IoCheckmarkDoneSharp color="#1c6fab" /> : <IoCheckmarkOutline />
                                )}
                            </div>
                            <img
                                src={message.sender_profile.profile_photo}
                                alt={`${message.sender_profile.first_name} ${message.sender_profile.last_name}`}
                                className="profile-photo"
                            />
                            <div className="message-content">
                                <div className="message-header">
                                    <h4>{`${message.sender_profile.first_name} ${message.sender_profile.last_name}`}</h4>
                                    <span>{new Date(message.date).toLocaleString()}</span>
                                </div>
                                <p>{message.message}</p>
                                {message.ad && (
                                    <div className="ad-info">
                                        <img src={`https://asmanmarket.com${message.ad.img}`} alt={message.ad.title} className="ad-image" />
                                        <div className="ad-details">
                                            <h3>{message.ad.title}</h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <form className="send-message-form" onSubmit={handleSendMessage}>
                <input
                    type="text"
                    placeholder="Введите сообщение..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                />
                <button
                    className={`send-message-button ${newMessage === '' ? 'disabled' : ''}`}
                    type="submit"
                    disabled={newMessage === '' || isMessageSent}
                >
                    {isMessageSent ? <LoadingAnimate /> : <FiSend size={20} />}
                </button>
            </form>
        </div>
    );
}
