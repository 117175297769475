import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../../Api';

const cateSlice = createSlice({
    name: 'cate',
    initialState: {
        cate: {
            active: "all",
            category: []
        },
        all_post: {
            data: [],
            result: [],
            nextPage: null
        }
    },
    reducers: {
        setCate(state, action) {
            state.cate = action.payload;
        },
        setAll_post(state, action) {
            state.all_post = action.payload;
        }
    }
});

export const { setCate, setAll_post } = cateSlice.actions;
export default cateSlice.reducer;

export const categoryTabs = (item) => {
    return async (dispatch) => {
        try {
            const response = await api.get(`/market/ad-list/?cat=${item.id}`);
            dispatch(setCate({ active: item.name, category: item.children }));
            dispatch(setAll_post({ data: response.data, result: response.data.results, nextPage: response.data.next }));
        } catch (error) {
            console.log(error);
        }
    }
};

export const getNextCategoryDatas = () => {
    return async (dispatch, getState) => {
        const { nextPage } = getState().cate.all_post;
        if (!nextPage) return;

        try {
            const response = await api.get(nextPage);
            dispatch(setAll_post((prevState) => ({
                data: [...prevState.data, ...response.data],
                result: [...prevState.result, ...response.data.results],
                nextPage: response.data.next
            })));
        } catch (error) {
            console.log(error);
        }
    }
};
