import React, { useEffect, useRef, useState, useCallback } from 'react';
import './magazin-page.css';
import Catchoises from './components/catchoises/catchoises';
import ProductCard from '../components/card/card';
import { useSelector, useDispatch } from 'react-redux';
import SkeletonAllDatas from './components/alldatas/skeleton';
import { getAllDatas, getNextDatas } from '../../../App/slice/market2/all-datas';
import { getDatas as arrayDatas } from '../../../App/slice/market2/category-datas';

export default function MagazinPage() {
    const dispatch = useDispatch();
    const getDatas = useSelector(state => state.all_dataPostSlice.data);
    const loadingRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getAllDatas());
        dispatch(arrayDatas([]));
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = () => {
            if (!loading && loadingRef.current && window.innerHeight + window.scrollY >= loadingRef.current.offsetTop) {
                setLoading(true);
                dispatch(getNextDatas())
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false));
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch, loading]);

    return (
        <div>
            <div>
                <Catchoises />
            </div>
            {getDatas.results && getDatas.results.length ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {getDatas.results.map((item, index) => <ProductCard key={index} data={item} />)}
                </div>
            ) : (
                <SkeletonAllDatas />
            )}
            <div ref={loadingRef} style={{ width: "100%", height: 100 }}></div>
        </div>
    );
}