import { configureStore } from '@reduxjs/toolkit'
import user_info from './slice/user-info'
import status from './slice/status'
import process from './slice/process'
import notification from './slice/notification'
import discount from './slice/discount'
import category from './slice/category'
import filter from './slice/market2/filter'
import tab from './slice/tab'
import catChoices from './slice/cat-choices'
import cateSlice from './slice/market/category-tabs'
import all_dataPostSlice from './slice/market2/all-datas'
import all_dataCategorySlice from './slice/market2/category-datas'
import myAds from './slice/market2/myAds'
import messagedetail from './slice/chat/detail-message'
// import marketReducer from './slice/market/all-datas'

export const store = configureStore({
    reducer: {
        user_info,
        status,
        process,
        notification,
        discount,
        category,
        filter,
        tab,
        catChoices,
        cateSlice,
        all_dataPostSlice,
        all_dataCategorySlice,
        myAds,
        message_details: messagedetail
    }
})