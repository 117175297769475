import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";
import axios from 'axios'

export const all_dataPostSlice = createSlice({
    name: 'all-datas',
    initialState: {
        data: {
            results: [],
            next: null
        },
    },
    reducers: {
        getDatas: (state, action) => {
            state.data = action.payload;
        },
        appendNextDatas: (state, action) => {
            state.data.results.push(...action.payload.results);
            state.data.next = action.payload.next;
        },
    }
});

export const { getDatas, appendNextDatas } = all_dataPostSlice.actions;
export default all_dataPostSlice.reducer;

export const getAllDatas = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const { pricefrom, priceto, city, sort, search } = state.filter;
        try {
            let response
            if (search) {
                response = await axios.get(`https://asmanmarket.com/api/market/ad-list/?search=${search}`)
            } else {
                response = await axios.get(`https://asmanmarket.com/api/market/ad-list/?pricefrom = ${pricefrom}${priceto !== 0 ? `&priceto=${priceto}` : ""
                    }${city && `&city=${city}`}${sort && `&ordering=${sort}`} `);
            }
            dispatch(getDatas(response.data));
        } catch (error) {
            console.log(error);
        }
    }
};

export const getNextDatas = () => {
    return async (dispatch, getState) => {
        const { all_dataPostSlice } = getState();
        if (!all_dataPostSlice.data.next) {
            return;
        }
        try {
            const response = await axios.get(all_dataPostSlice.data.next);
            const uniqueResults = response.data.results.filter(item => (
                !all_dataPostSlice.data.results.some(existingItem => existingItem.id === item.id)
            ));
            dispatch(appendNextDatas({ results: uniqueResults, next: response.data.next }));
        } catch (error) {
            console.log(error);
        }
    };
};

