import { useEffect, useState } from "react";
import { api } from "../../Api";
import MessageCard from "./components/messageCard/messageCard";
import './messages.css';

export default function Messages() {
    const [messagebox, setMessagebox] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMessages, setFilteredMessages] = useState([]);

    const getMessages = async () => {
        try {
            const response = await api.get("/chat/my-inbox/");
            setMessagebox(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getMessages();
    }, []);

    useEffect(() => {
        setFilteredMessages(
            messagebox.filter(message =>
                message.sender_profile.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.sender_profile.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.receiver_profile.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.receiver_profile.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                message.message.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, messagebox]);

    return (
        <div className="messages-page">
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Поиск чатов..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="message-container">
                {filteredMessages.map((message, index) => (
                    <MessageCard key={index} message={message} />
                ))}
            </div>
            <div style={{ height: '100px', width: '100%' }}></div>
        </div>
    );
}
