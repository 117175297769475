import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../../Api'


const my_ads = createSlice({
    name: 'my-ads',
    initialState: {
        myAds: []
    },
    reducers: {
        setMyAds(state, action) {
            state.myAds = action.payload
        }
    }
})
export default my_ads.reducer
export const { setMyAds } = my_ads.actions


export const fetchMyAds = () => async (dispatch) => {
    try {
        const res = await api.get("/market/my-ads")
        dispatch(setMyAds(res.data))
    } catch (error) {
        console.log(error);
    }
}