import React from 'react'

export default function Rules() {
    return (
        <div>
            <p style={{ padding: '5px' }}>
                Пользовательское соглашение для ASMAN DISCOUNT <br />

                Добро пожаловать в ASMAN DISCOUNT, платформу уникальных возможностей и эксклюзивных предложений! Пожалуйста, внимательно ознакомьтесь с нашими условиями использования: <br />

                1.   Регистрация и аккаунт: <br />
                - Для использования платформы необходимо зарегистрировать аккаунт.<br />
                - При регистрации вы обязуетесь предоставить достоверные и актуальные данные.<br />
                - Аккаунт на платформе является личным и не подлежит передаче третьим лицам.<br />

                2.   Конфиденциальность и безопасность:<br />
                - Мы обеспечиваем конфиденциальность ваших данных и обязуемся не передавать их третьим лицам.<br />
                - Пожалуйста, сохраняйте свои учетные данные в тайне и не делитесь ими с кем-либо еще.<br />

                3.   Использование платформы:<br />
                - Пользователи обязуются использовать ASMAN DISCOUNT согласно его предназначению и не нарушать правила платформы.<br />
                - Мы оставляем за собой право ограничивать доступ к сервисам платформы в случае нарушения условий использования.<br />

                4.   Ответственность:<br />
                4.1. Мы несем ответственность за качество и предоставление услуг, описанных на платформе ASMAN DISCOUNT.<br />
                4.2. Однако мы не несем ответственности за потери или убытки, понесенные пользователями в результате изменений курса криптовалюты ASMAN COIN после покупки товара или услуги через нашу платформу.<br />
                4.3. В платформе нельзя продавать нелегальные товары, и мы оставляем за собой право блокировать доступ к таким товарам.<br />

                5.   Личный договор и качество товара:<br />
                - Покупка и продажа товаров через нашу платформу осуществляется по личному договору обеих сторон.<br />
                - Мы не несем ответственности за качество товаров, купленных через ASMAN MARKET, однако будем стремиться предоставить максимально удовлетворительный опыт для наших пользователей.<br />

                6.   Комиссии:<br />
                - При использовании платформы ASMAN DISCOUNT могут взиматься комиссии за определенные услуги или транзакции.<br />
                - Эти комиссии могут обновляться, и пользователи обязуются ознакомиться с ними перед совершением операций.<br />

                7.   Изменения и обновления:<br />
                - Мы оставляем за собой право вносить изменения в пользовательское соглашение без предварительного уведомления.<br />
                - Пользователи обязуются регулярно проверять условия использования на наличие обновлений.<br />

                Пользуясь ASMAN DISCOUNT, вы соглашаетесь с этими условиями. Приятных вам покупок и эксклюзивных предложений!<br />
            </p>
        </div>
    )
}
