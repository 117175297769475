import React, { useState, useEffect } from "react";
import "./ads-detail.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { LuShare2 } from "react-icons/lu";
import Slider from "react-slick";
import heart from "../../../views/market/heart.svg";
import heart_red from "../../../views/market/heart_red.svg";
import LoadingAnimate from "../../../UI-kit/loading";
import { FaCity } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import ProductCard from "../../market2/components/card/card";
import { api } from "../../../Api";

const AdsDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [love, setLove] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const predefinedMessages = ["Еще актуально?", "Здравствуйте", "Можно больше информации?"];

  useEffect(() => {
    api.get(`/market/ad-detail/${id}`)
      .then((response) => {
        console.log(response.data.images);
        setData(response.data);
        setLoading(false);
        if (response.data.isfavorite) {
          setLove(true);
        }
      })
      .catch((error) => {
        console.log("/market/ad-detail/:", error);
        setLoading(false);
      });
  }, [id]);

  const uniqueImages = Array.from(new Set(data.images?.map(img => img.img))).map(img => {
    return data.images.find(image => image.img === img);
  });

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sendFavoriteId = (e) => {
    e.stopPropagation();
    setLove(!love);
    api.post(`/market/favourite/${data.id}/`)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShareLink = async () => {
    try {
      await navigator.share({
        title: data.title,
        text: data.description,
        url: `https://asmanmarket.com/#/market-detail/${data.id}`,
      });
    } catch (error) {
      console.error("Ошибка обмена:", error.message);
    }
  };

  const openMessageModal = () => {
    setModal(true);
  };

  const sendSelectedMessage = async (msg) => {
    const user_info = localStorage.getItem('userID');
    try {
      await api.post('/chat/send-messages/', {
        user: user_info,
        sender: user_info,
        receiver: data.user_id,
        message: msg,
        ad: data.id
      });
      navigate(`/detail-message/${user_info}/${data.user_id}`);
      setModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="ads_detail">
      <div className="head_detail">
        <div className="between">
          <IoMdArrowBack onClick={() => navigate('/market')} size={24} />
          <div className="flex">
            <LuShare2 onClick={handleShareLink} size={24} />
          </div>
        </div>
      </div>
      {modal &&
        <MessageModal
          closeModal={() => setModal(false)}
          predefinedMessages={predefinedMessages}
          sendSelectedMessage={sendSelectedMessage}
        />
      }
      {loading ? (
        <div className="loading_div">
          <LoadingAnimate />
        </div>
      ) : (
        <div>
          {uniqueImages.length > 1 ? (
            <Slider {...settings} className="box_slide">
              {uniqueImages.map((elem, id) => (
                <div key={id}>
                  <img className="ads_detail_photo" src={`https://asmanmarket.com${elem.img}`} alt="" />
                </div>
              ))}
            </Slider>
          ) : (
            <div>
              <img className="ads_detail_photo" src={`https://asmanmarket.com${data.images?.[0]?.img}`} alt="" />
            </div>
          )}
          <div className="contants">
            <div className="between">
              <div className="flex">
                <IoEyeSharp /> {data.views}
              </div>
              <div className="flex">
                {data.favorites_count}
                <img
                  className="loves"
                  onClick={sendFavoriteId}
                  src={love ? heart_red : heart}
                  alt=""
                />
              </div>
            </div>
            <div className="between">
              <p className="title">{data.title}</p>
            </div>
            <p className="description" dangerouslySetInnerHTML={{ __html: data.description }} />
            <p className="prices"><span>цена: </span> {data.price} {data.currency}</p>
            <p className="prices"><span>цена за asman: </span> {data.price_asman}</p>
            <div className="user">
              <img src={data.avatar} alt="" />
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <p className="user_title">{data.first_name} {data.last_name}</p>
                <div style={{ cursor: 'pointer', color: 'var(--orange)', textDecoration: 'underline' }} onClick={openMessageModal}>Написать</div>
              </div>
            </div>
            <div className="user">
              <FaCity style={{ marginRight: 10 }} color="var(--orange)" size={24} />
              <div>
                <p className="user_title">{data.city}</p>
                <p className="texting">Город</p>
              </div>
            </div>
            <div className="user r">
              <MdPhone style={{ marginRight: 10 }} color="var(--orange)" size={24} />
              <div>
                <p className="user_title">{data.number}</p>
                <p className="texting">Номер телефона</p>
              </div>
            </div>
            <div className="line_boxs">
              <h2>Похожие товары</h2>
            </div>
          </div>
          <div className="ovar_boxs">
            {data.similar_ads.map((el, index) => (
              <ProductCard key={index} data={el} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MessageModal = ({ closeModal, predefinedMessages, sendSelectedMessage }) => (
  <div className='product-card__top__user__message'>
    <div className='product-card__top__user__message__text'>
      <h3>Выберите сообщение</h3>
      {predefinedMessages.map((msg, index) => (
        <div key={index} className='product-card__top__user__message__option' onClick={() => sendSelectedMessage(msg)}>
          {msg}
        </div>
      ))}
      <div className='product-card__top__user__message__close' onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}>x</div>
    </div>
  </div>
);

export default AdsDetail;