import { createSlice } from "@reduxjs/toolkit";

const tab = createSlice({
  name: "tab",
  initialState: {
    tab: true,
  },
  reducers: {
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { changeTab } = tab.actions;
export default tab.reducer;
