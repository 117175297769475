import { Skeleton } from '@mui/material'
import React from 'react'

export default function SkeletonAllDatas() {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', position: 'relative', top: '-100px' }}>
            <Skeleton sx={{ bgcolor: 'grey.200' }} width="48%" height={400} variant="text" />
            <Skeleton sx={{ bgcolor: 'grey.200' }} width="48%" height={400} variant="text" />
        </div>
    )
}