import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";
import axios from 'axios'

export const all_dataCategorySlice = createSlice({
    name: 'category-datas',
    initialState: {
        data: []
    },
    reducers: {
        getDatas: (state, action) => {
            state.data = action.payload;
        },
        appendNextDatas: (state, action) => {
            state.data.results.push(...action.payload.results);
            state.data.next = action.payload.next;
        }
    }
});

export const { getDatas, appendNextDatas, setCate } = all_dataCategorySlice.actions;
export default all_dataCategorySlice.reducer;

export const getNextCategoryDatas = () => {
    return async (dispatch, getState) => {
        const { all_dataCategorySlice } = getState();

        if (!all_dataCategorySlice.data.next) {
            return;
        }
        try {
            const response = await axios.get(all_dataCategorySlice.data.next);
            const uniqueResults = response.data.results.filter(item => (
                !all_dataCategorySlice.data.results.some(existingItem => existingItem.id === item.id)
            ));
            dispatch(appendNextDatas({ results: uniqueResults, next: response.data.next }));
        } catch (error) {
            console.log(error);
        }
    };
};

export const categoryTabs = (id) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`https://asmanmarket.com/api/market/ad-list/?cat=${id}`);
            dispatch(getDatas(response.data));
        } catch (error) {
            console.log(error);
        }
    }
}