import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './catchoises.css'
import SkeletonCatchoises from './skeleton'
import { TiThSmallOutline } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import { categoryTabs } from '../../../../../App/slice/market/category-tabs';
import { getAllDatas } from '../../../../../App/slice/market2/all-datas';
import { changeCity, changePricefrom, changePriceto, changeSort } from '../../../../../App/slice/market2/filter';

export default function Catchoises() {
    const catchoises = useSelector(state => state.catChoices.data)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    function empty() {
        dispatch(changePricefrom(""));
        dispatch(changePriceto(""));
        dispatch(changeCity(""));
        dispatch(changeSort(""));
        dispatch(getAllDatas(""));
        navigate('/market')
    }
    return (
        <div className='catchoises-container'>
            {
                catchoises.length ? <div onClick={empty} style={{ padding: '10px 20px' }} className='cate'>
                    <TiThSmallOutline />
                    <p>Все</p>
                </div> : ""
            }
            {
                catchoises.length ? catchoises.map(item => {
                    return (
                        <div
                            className='cate'
                            key={item.name}
                            onClick={() => {
                                navigate(`/market-category/${item.id}`)
                                dispatch(categoryTabs(item))
                            }}
                        >
                            <img style={{ width: 20, height: 20 }} src={item.icon} alt="" />
                            <p>{item.name}</p>
                        </div>
                    )
                }) : <SkeletonCatchoises />
            }
        </div >
    )
}
