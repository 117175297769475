import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoryTabs, getNextCategoryDatas, setAll_post, setCate } from '../../../App/slice/market/category-tabs';
import ProductCard from '../components/card/card';
import { FiArrowLeft } from "react-icons/fi";
import search from "../../../views/market/search.svg";
import { getCatChoices } from '../../../App/slice/cat-choices';
import { Skeleton } from "@mui/material";
import LoadingAnimate from '../../../UI-kit/loading';
import './category-page.css';
import './category.css';

export default function CategoryPage() {
    const dispatch = useDispatch();
    const loadingRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [valuePage, setValuePage] = useState("");
    const cate = useSelector((state) => state.cateSlice.cate);
    const all_post = useSelector((state) => state.cateSlice.all_post);
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (!loading && loadingRef.current && window.innerHeight + window.scrollY >= loadingRef.current.offsetTop) {
                setLoading(true);
                dispatch(getNextCategoryDatas())
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false));
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch, loading]);

    useEffect(() => {
        dispatch(getCatChoices())
            .finally(() => setInitialLoading(false));
    }, [dispatch]);

    const clearDatas = () => {
        // setValuePage("");
        // dispatch(setCate({ ...cate, category: [] }));
        // dispatch(setAll_post({ ...all_post, result: [] }));
        window.history.back();
    };

    return (
        <div className='category-page'>
            <div>
                <div className='category-page_top'>
                    <FiArrowLeft size={25} onClick={clearDatas} />
                </div>
            </div>
            <div className="relative_input">
                <input
                    value={valuePage}
                    onChange={(e) => setValuePage(e.target.value)}
                    className="search"
                    type="text"
                    placeholder="Search..."
                />
                <img className="icon absolute" src={search} alt="" />
            </div>
            <div className='category'>
                {
                    cate.category && cate.category.map((el, index) => (
                        el.name ? (
                            <div onClick={() => dispatch(categoryTabs(el))} className='category_button' key={index}>
                                <h1>{el.name}</h1>
                            </div>
                        ) : <Skeleton className='category_button' width={200} height={50} key={index} />
                    ))
                }
            </div>
            {
                initialLoading ? (
                    <div style={{ width: '100%', height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LoadingAnimate />
                    </div>
                ) : all_post && all_post.result && all_post.result.length ? (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {all_post.result.map((item, index) => <ProductCard key={index} data={item} />)}
                    </div>
                ) : (
                    <div style={{ width: '100%', height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        Товаров пока нет
                    </div>
                )
            }
            {loading && (
                <div style={{ width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingAnimate />
                </div>
            )}
            <div ref={loadingRef} style={{ width: "100%", height: 100 }}></div>
        </div>
    );
}