import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../Api";

function MessageCard({ message }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [messageData, setMessageData] = useState([])
    const user_info = localStorage.getItem('userID');

    useEffect(() => {
    }, [dispatch]);

    const { sender_profile, receiver_profile, message: text, date, is_read, sender, receiver } = message;
    const { first_name: senderFirstName, last_name: senderLastName, profile_photo: senderPhoto } = sender_profile;
    const { first_name: receiverFirstName, last_name: receiverLastName, profile_photo: receiverPhoto } = receiver_profile;

    const messageDatas = async () => {
        try {
            const response = await api.get(`/chat/get-messages/${sender}/${receiver}`);
            setMessageData(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        messageDatas();
    }, []);

    const filteredIsRead = messageData?.filter(el => el.is_read === false)
    let viewsArr = []
    filteredIsRead?.map(item => (
        viewsArr.push(item.id)
    ))
    const is_read_message = async () => {
        try {
            await api.post('/chat/update-is_read/', {
                "message_ids": viewsArr
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        user_info && < div className="message-card" onClick={() => {
            is_read_message()
            navigate(`/detail-message/${sender}/${receiver}`)
        }}>
            <img src={user_info === sender_profile.id ? receiverPhoto : senderPhoto} alt={`${user_info === sender_profile ? receiverFirstName : senderFirstName} ${user_info === sender_profile.id ? receiverLastName : senderLastName}`}
                className="profile-photo" />
            <div className="message-content">
                <div className="message-header">
                    <h4>{`${user_info === sender_profile.id ? receiverFirstName : senderFirstName} ${user_info === sender_profile.id ? receiverLastName : senderLastName}`}</h4>
                    <span style={!is_read && user_info !== sender_profile.id ? { color: 'var(--orange)' } : {}}>{new Date(date).toLocaleString()}</span>
                </div>
                <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>{user_info === sender_profile.id ? (!is_read ? <IoCheckmarkOutline /> : <IoCheckmarkDoneSharp color="#1c6fab" />) : (!is_read ? <div className="is-read-message"></div> : "")} {text}</p>
            </div>
        </div>
    );
}

export default MessageCard