import React from 'react'
import { Skeleton } from '@mui/material'

export default function SkeletonCatchoises() {
    return (
        <div style={{ display: 'flex', gap: 3, position: 'relative', top: '-20px' }}>
            <Skeleton sx={{ bgcolor: 'grey.200' }} width={100} height={80} variant="text" />
            <Skeleton sx={{ bgcolor: 'grey.200' }} width={100} height={80} variant="text" />
            <Skeleton sx={{ bgcolor: 'grey.200' }} width={100} height={80} variant="text" />
            <Skeleton sx={{ bgcolor: 'grey.200' }} width={100} height={80} variant="text" />
            <Skeleton sx={{ bgcolor: 'grey.200' }} width={100} height={80} variant="text" />
        </div>
    )
}
