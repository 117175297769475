import React from "react";
import "./header.css";
import { IoNotifications } from "react-icons/io5";
import { useSelector } from "react-redux";
// import Skeleton from "react-loading-skeleton";
import { NavLink, useNavigate } from "react-router-dom";
import { api } from "../../Api";
import { FaTelegramPlane } from "react-icons/fa";
import { Skeleton } from "@mui/material";

export default function Header() {
  const names = useSelector((state) => state.user_info.user_info);
  const news = useSelector((state) => state.notification.news);
  const newsLength = news.filter(el => el.is_read === false)
  const sumnotifications = names.notifications + newsLength.length
  const navigate = useNavigate()

  return (
    <div className="contain-header">
      <div className="header">
        <div className="profile-name" onClick={() => navigate('/profile')}>
          {names.profile_photo ? (
            <img
              className="profile-photo-header"
              src={names.profile_photo}
              alt=""
            />
          ) : (
            <Skeleton sx={{ bgcolor: 'grey.900' }} width={50} height={50} variant="circular" />
          )}
          <h1>{names.first_name || <Skeleton sx={{ bgcolor: 'grey.900' }} width={70} height={10} variant="text" />}</h1>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <NavLink to='https://t.me/Asmancoin'><FaTelegramPlane size={25} /></NavLink>
          <div onClick={() => navigate('/natification-page')} style={{ margin: '5px', position: "relative" }}>
            {sumnotifications > 0 ? <div className="count_notification">{sumnotifications}</div> : ""}
            <IoNotifications size={25} color="#E2A332" />
          </div>
        </div>
      </div>
    </div>
  );
}
