import React from "react";
import "./ConfirmationModal.css";

const ConfirmationModal = ({ onConfirm, onCancel, mfa }) => {
    return (
        <div className="confirmation-modal">
            <div className="modal-contenttt">
                <h2>Подтверждение действия</h2>
                <p>{`Вы действительно хотите ${mfa ? 'выключить' : 'включить'} двухфакторную аутентификацию?`}</p>
                <div className="modal-actions">
                    <button onClick={onConfirm}>Да</button>
                    <button onClick={onCancel}>Нет</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
