import React, { useEffect } from 'react'
import './notification-page.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { BiTransfer } from "react-icons/bi";
import { AiTwotoneGift } from "react-icons/ai";
import { FaRegNewspaper } from "react-icons/fa6";
import { FiChevronLeft } from 'react-icons/fi';
import { RiHistoryLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../Api';
import { IoNotifications } from 'react-icons/io5';
import { fetchNotifData, fetchNotifNews } from '../../App/slice/notification';

export default function NotificationPage() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const notif = useSelector((state) => state.notification.notification);
    const news = useSelector((state) => state.notification.news);
    const names = useSelector((state) => state.user_info.user_info);

    const filteredNotif = notif.filter(el => el.is_viewed === false)
    let viewsArr = []
    filteredNotif.map(item => (
        viewsArr.push(item.id)
    ))
    const newsLength = news.filter(el => el.is_read === false)


    useEffect(() => {
        dispatch(fetchNotifData())
        dispatch(fetchNotifNews())
    }, [dispatch])

    const readedNews = async () => {
        try {
            if (newsLength.length) {
                await Promise.all(newsLength.map(async item => {
                    if (!item.is_read) {
                        await api.post(`/news/${item.id}/`);
                    }
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateNotif = async () => {
        try {
            if (names.notifications) {
                await api.post('payment/update-notifications/', {
                    "notification_ids": viewsArr
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className='notifictaionpage_container'>
            <div>
                <FiChevronLeft color='#fdb602' onClick={() => navigate('/')} size={40} />
                <span>Уведомление</span>
            </div>
            <NavLink onClick={() => updateNotif()} to='/notification' className='notification_item'>
                <div>
                    <BiTransfer />
                    Переводы
                </div>
                <div style={{ margin: '5px', position: "relative" }}>
                    {names.notifications > 0 ? <div className="count_notification">{names.notifications}</div> : ""}
                </div>
            </NavLink>
            <NavLink to='/payments' className='notification_item'>
                <div>
                    <RiHistoryLine />
                    История
                </div>
            </NavLink>
            <NavLink onClick={() => readedNews()} to='/news-notification' className='notification_item'>
                <div>
                    <FaRegNewspaper />
                    Новости
                </div>
                <div style={{ margin: '5px', position: "relative" }}>
                    {newsLength.length > 0 ? <div className="count_notification">{newsLength.length}</div> : ""}
                </div>
            </NavLink>
        </div>
    )
}