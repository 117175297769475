import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

const messagedetail = createSlice({
    name: "message-details",
    initialState: {
        data: [],
    },
    reducers: {
        getDatas: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { getDatas } = messagedetail.actions;
export default messagedetail.reducer;


export const getMessageDetails = (sender, receiver) => {
    return async (dispatch) => {
        try {
            const response = await api.get(`/chat/get-messages/${sender}/${receiver}`);
            dispatch(getDatas(response.data));
        } catch (error) {
            console.log(error);
        }
    }
}