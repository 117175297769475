import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../Api";

const catchoises = createSlice({
    name: "category-choices",
    initialState: {
        data: [],
    },
    reducers: {
        getDatas: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { getDatas } = catchoises.actions;
export default catchoises.reducer;


export const getCatChoices = () => {
    return async (dispatch) => {
        try {
            const response = await api.get("/market/cat-choices/");
            dispatch(getDatas(response.data));
        } catch (error) {
            console.log(error);
        }
    }
}