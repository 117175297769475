import React from 'react'
import TabBar from '../tab-bar/tab-bar'
import PageContent from '../page-content'

export default function LayOut() {
    return (
        <div>
            <PageContent />
            <TabBar />
        </div>
    )
}
