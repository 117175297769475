import React, { useState, useEffect } from 'react';
import './card.css';
import { FaRegUserCircle } from 'react-icons/fa';
import { TiMessages } from 'react-icons/ti';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { api } from '../../../../Api';
import LoadingAnimate from '../../../../UI-kit/loading';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ data, navigates }) => {
    const [love, setLove] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (data.isfavorite !== undefined) {
            setLove(data.isfavorite);
        }
    }, [data.isfavorite]);

    const toggleLove = (e) => {
        e.stopPropagation();
        const newLove = !love;
        setLove(newLove);
        setLoading(true);
        api.post(`/market/favourite/${data.id}/`)
            .then(() => setLoading(false))
            .catch(error => {
                console.log(error);
                setLove(!newLove);
                setLoading(false);
            });
    };

    const sendMessage = (e) => {
        e.stopPropagation();
        setModal(true);
    };

    const sendSelectedMessage = async (msg) => {
        const user_info = localStorage.getItem('userID');
        try {
            await api.post('/chat/send-messages/', {
                user: user_info,
                sender: user_info,
                receiver: data.user_id,
                message: msg,
                ad: data.id
            });
            navigate(`/detail-message/${user_info}/${data.user_id}`);
            setModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const formattedDate = new Date(data.date_update).toLocaleDateString();

    return (
        <div className="product-card" onClick={() => navigates ? navigate(`/market-ads-update/${data.id}`) : navigate(`/market-detail/${data.id}`)}>
            <div className='product-card__top'>
                <div className="product-card__top__user"><FaRegUserCircle size={15} /> {data.full_name}</div>
                <div className="product-card__top__user" onClick={sendMessage} style={{ cursor: 'pointer' }}>
                    <TiMessages size={20} />
                    {modal &&
                        <div className='product-card__top__user__message'>
                            <div className='product-card__top__user__message__text'>
                                <h3>Выберите сообщение</h3>
                                {["Еще актуально?", "Здравствуйте", "Можно больше информации?"].map((msg, index) => (
                                    <div key={index} className='product-card__top__user__message__option' onClick={() => sendSelectedMessage(msg)}>
                                        {msg}
                                    </div>
                                ))}
                                <div className='product-card__top__user__message__close' onClick={(e) => {
                                    e.stopPropagation();
                                    setModal(false);
                                }}>x</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="product-card__image">
                <img src={`https://asmanmarket.com${data.images[0]?.img}`} alt="" />
            </div>
            <div className="product-card__info">
                <div className="product-card__info__price">{data.price} {data.currency}</div>
                <div className="product-card__info__price2">{data.price_asman} asman</div>
                <div className="product-card__info__title">{data.title.split(' ').slice(0, 1).join(' ')}...</div>
                <div className="product-card__info__description">дата обновления: {formattedDate}</div>
            </div>
            {loading ? <div className='product-card__like'><LoadingAnimate /></div> :
                love ? (
                    <AiFillLike onClick={toggleLove} color='var(--orange)' style={{ cursor: 'pointer' }} size={20} className='product-card__like' />
                ) : (
                    <AiOutlineLike onClick={toggleLove} size={20} style={{ cursor: 'pointer' }} className='product-card__like' />
                )}
        </div>
    );
};

export default ProductCard;
